import Header from '../Header/Header';
import './App.css';
import ImageContainer from '../ImageContainer/ImageContainer';

const App = () => {

  return (
    <main className='main'>
      <Header />
      <ImageContainer />
    </main>
  );
}

export default App;
